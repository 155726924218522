<template>
    <div class="social_ico_wrapper flx">
      <a href="https://t.me/brewdrop1" class="item_social flx">
        <img width="20px" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOnVybCgjU1ZHSURfMV8pO30KCS5zdDF7ZmlsbDojRkZGRkZGO30KCS5zdDJ7ZmlsbDojRDJFNEYwO30KCS5zdDN7ZmlsbDojQjVDRkU0O30KPC9zdHlsZT48Zz48bGluZWFyR3JhZGllbnQgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGlkPSJTVkdJRF8xXyIgeDE9IjI1NiIgeDI9IjI1NiIgeTE9IjAiIHkyPSI1MTAuMTMyMiI+PHN0b3Agb2Zmc2V0PSIwIiBzdHlsZT0ic3RvcC1jb2xvcjojNDFCQ0U3Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdHlsZT0ic3RvcC1jb2xvcjojMjJBNkRDIi8+PC9saW5lYXJHcmFkaWVudD48Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyNTYiIGN5PSIyNTYiIHI9IjI1NiIvPjxnPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zODAuNiwxNDcuM2wtNDUuNywyMzAuNWMwLDAtNi40LDE2LTI0LDguM2wtMTA1LjUtODAuOUwxNjcsMjg2LjdsLTY0LjYtMjEuN2MwLDAtOS45LTMuNS0xMC45LTExLjIgICAgYy0xLTcuNywxMS4yLTExLjgsMTEuMi0xMS44bDI1Ni44LTEwMC43QzM1OS41LDE0MS4yLDM4MC42LDEzMS45LDM4MC42LDE0Ny4zeiIvPjxwYXRoIGNsYXNzPSJzdDIiIGQ9Ik0xOTcuMiwzNzUuMmMwLDAtMy4xLTAuMy02LjktMTIuNGMtMy44LTEyLjEtMjMuMy03Ni4xLTIzLjMtNzYuMWwxNTUuMS05OC41YzAsMCw5LTUuNCw4LjYsMCAgICBjMCwwLDEuNiwxLTMuMiw1LjRjLTQuOCw0LjUtMTIxLjgsMTA5LjctMTIxLjgsMTA5LjciLz48cGF0aCBjbGFzcz0ic3QzIiBkPSJNMjQ1LjgsMzM2LjJsLTQxLjcsMzguMWMwLDAtMy4zLDIuNS02LjgsMC45bDgtNzAuNyIvPjwvZz48L2c+PC9zdmc+" alt="">
      </a>
      <a href="https://vk.com/brewdrop1" class="item_social flx">
        <img width="20px" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0RDc2QTE7fQoJLnN0MXtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiNGRkZGRkY7fQo8L3N0eWxlPjxnPjxjaXJjbGUgY2xhc3M9InN0MCIgY3g9IjI1NiIgY3k9IjI1NiIgcj0iMjU2Ii8+PHBhdGggY2xhc3M9InN0MCIgZD0iTTUxMiwyNTYiLz48cGF0aCBjbGFzcz0ic3QxIiBkPSJNMjQ2LjMsMzY4LjNoMjAuMWMwLDAsNi4xLTAuNyw5LjItNGMyLjktMy4xLDIuOC04LjgsMi44LTguOHMtMC40LTI3LDEyLjEtMzAuOWMxMi4zLTMuOSwyOC4yLDI2LDQ1LDM3LjYgICBjMTIuNyw4LjcsMjIuMyw2LjgsMjIuMyw2LjhsNDQuOS0wLjZjMCwwLDIzLjUtMS40LDEyLjMtMTkuOWMtMC45LTEuNS02LjUtMTMuNy0zMy40LTM4LjZjLTI4LjEtMjYuMS0yNC40LTIxLjksOS41LTY3LjEgICBjMjAuNy0yNy41LDI4LjktNDQuMywyNi4zLTUxLjVjLTIuNS02LjktMTcuNi01LTE3LjYtNWwtNTAuNiwwLjNjMCwwLTMuNy0wLjUtNi41LDEuMWMtMi43LDEuNi00LjUsNS40LTQuNSw1LjRzLTgsMjEuMy0xOC43LDM5LjQgICBjLTIyLjUsMzguMi0zMS41LDQwLjItMzUuMiwzNy45Yy04LjYtNS42LTYuNC0yMi4yLTYuNC0zNC4xYzAtMzcsNS42LTUyLjUtMTAuOS01Ni41Yy01LjUtMS4zLTkuNS0yLjItMjMuNi0yLjMgICBjLTE4LTAuMi0zMy4zLDAtNDIsNC4zYy01LjcsMi44LTEwLjIsOS4xLTcuNSw5LjRjMy4zLDAuNCwxMC45LDIuMSwxNC45LDcuNWM1LjIsNyw1LDIyLjksNSwyMi45czMsNDMuNi03LDQ5ICAgYy02LjgsMy43LTE2LjItMy45LTM2LjItMzguNWMtMTAuMy0xNy44LTE4LTM3LjQtMTgtMzcuNHMtMS41LTMuNy00LjItNS42Yy0zLjMtMi40LTcuOC0zLjEtNy44LTMuMWwtNDgsMC4zYzAsMC03LjIsMC4yLTkuOSwzLjMgICBjLTIuMywyLjgtMC4yLDguNi0wLjIsOC42czM3LjYsODgsODAuMiwxMzIuM0MyMDIsMzcxLDI0Ni4zLDM2OC4zLDI0Ni4zLDM2OC4zTDI0Ni4zLDM2OC4zeiBNMjQ2LjMsMzY4LjMiLz48L2c+PC9zdmc+" alt="">
      </a>
    </div>
</template>

<script>
export default {
  name: 'SocialLinks'
}
</script>

<style scoped>

</style>
